import { Form, Input, Checkbox, Row, Button, Typography, Col, message } from 'ant-design-vue';
import { defineComponent, reactive } from 'vue';
import config from '@/utils/config';
import { localOperation, round } from '@/utils';
import Algorithm from '@/components/Algorithm';
import { useRouter } from 'vue-router';
import './index.less';
const { Text } = Typography;
const { total, title, subject } = config;
const customCount = (formState: any) => {
  let count = 0;
  const len = formState.subjectCustom;
  for (let i = 0; i < len.length; i++) {
    for (let j = 0; j < len[i].length; j++) {
      if (len[i][j]) {
        count += 1;
      }
    }
  }
  return count;
};
const setChange = (formState: any) => (e: any) => {
  const newSubject = formState.subjectList.map((item: any) => {
    if (item.type === e.type) {
      item = e;
      return item;
    } else {
      return item;
    }
  });
  if (formState.patchSubject) {
    formState.patchSubject = formState.patchSubject.map((item: any) => {
      if (item.type === e.type) {
        item = e;
        return item;
      } else {
        return item;
      }
    });
  }
  const reduxPrice =
    formState.patchSubject.reduce((total: any, item: any) => {
      return total + item.current;
    }, 0) ||
    newSubject.reduce((total: any, item: any) => {
      return total + item.current;
    }, 0);
  console.log(reduxPrice);
  formState.total = reduxPrice + customCount(formState);

  formState.subjectList = newSubject;
};
const sumbit = (formState: any, $router: any) => () => {
  const subjectCustom = formState.subjectCustom;
  const newCustom = [];
  for (let i = 0; i < subjectCustom.length; i++) {
    for (let j = 0; j < subjectCustom[i].length; j++) {
      newCustom.push(subjectCustom[i][j]);
    }
  }
  const isVerification = newCustom.every((item) => {
    return item.verification === true;
  });
  if (!isVerification) {
    message.error('请先处理完所有错误的部分，再保存');
    return;
  }
  if (formState.total < 1) {
    message.error('请先至少留有一题，再保存');
    return;
  }

  localOperation.set({
    key: 'subjectList',
    value: JSON.stringify(formState.subjectList),
  });

  if (formState.patchSubject.length === 0) {
    localOperation.set({
      key: 'patchSubject',
      value: JSON.stringify(formState.subjectList),
    });
  } else {
    localOperation.set({
      key: 'patchSubject',
      value: JSON.stringify(formState.patchSubject),
    });
  }

  localOperation.set({
    key: 'patchCustom',
    value: JSON.stringify(formState.patchCustom),
  });
  localOperation.set({
    key: 'total',
    value: formState.total,
  });
  localOperation.set({
    key: 'title',
    value: formState.title,
  });
  localOperation.set({
    key: 'subjectCustom',
    value: JSON.stringify(formState.subjectCustom),
  });
  localOperation.set({
    key: 'type',
    value: JSON.stringify(formState.type),
  });

  message.success('设置成功');
  $router.replace({ path: '/' });
};
const setSubjectCustom = (formState: any) => (data: any, type: number) => {
  formState.subjectCustom[type - 1] = data;
};
const setTotleChange = (formState: any) => (e: number) => {
  formState.total = formState.total + e;
};
const Home = defineComponent({
  setup() {
    const $router = useRouter();
    const subjectStore = JSON.parse(localOperation.get('subjectList') as any);
    const patchSubjectStore = JSON.parse(localOperation.get('patchSubject') as any);
    const patchCustomStore = JSON.parse(localOperation.get('patchCustom') as any);
    const totalStore = Number(localOperation.get('total'));
    const titleStore = localOperation.get('title');
    const subjectCustomStore = JSON.parse(localOperation.get('subjectCustom') as any);
    const typeStore = JSON.parse(localOperation.get('type') as any);
    const formState: any = reactive({
      title: titleStore || title,
      type: typeStore || [1, 2, 3, 4, 5],
      total: totalStore || total,
      subjectList: subjectStore || subject,
      subjectCustom: subjectCustomStore || [[], [], [], [], []],
      patchSubject: patchSubjectStore || [],
      patchCustom: patchCustomStore || [],
    });

    return () => (
      <div class="answerSet-container">
        <Form model={formState}>
          <div class="title">
            <h2>标题设置</h2>
            <Form.Item label="标题" name="title" rules={[{ required: true, message: '请输入标题' }]}>
              <Input v-model={[formState.title, 'value']} />
            </Form.Item>
          </div>
          <div class="answer-set">
            <Row align="middle" justify="space-between">
              <h2>题目设置</h2>
              <Row>
                <h2 style={{ marginRight: '110px' }}>超时</h2>
                <h2 style={{ marginRight: '50px' }}>数量</h2>
              </Row>
            </Row>
            <Checkbox.Group
              v-model={[formState.type, 'value']}
              onChange={(e) => {
                formState.patchSubject = [...formState.subjectList];
                formState.patchCustom = [...formState.subjectCustom];
                const pachData = [1, 2, 3, 4, 5];
                for (let i = 0; i < e.length; i++) {
                  if (pachData.includes(e[i])) {
                    pachData.splice(pachData.indexOf(e[i]), 1);
                  }
                }
                if (formState.patchSubject.length >= 1) {
                  for (let i = 0; i < pachData.length; i++) {
                    for (let j = 0; j < formState.patchSubject.length; j++) {
                      if (formState.patchSubject[j].type === pachData[i]) {
                        formState.patchSubject.splice(j, 1);
                      }
                    }
                  }
                }
                if (formState.patchCustom.length >= 1) {
                  for (let i = 0; i < pachData.length; i++) {
                    for (let j = 0; j < formState.patchCustom.length; j++) {
                      if (formState.patchCustom[j].type === pachData[i]) {
                        formState.patchCustom.splice(j, 1);
                      }
                    }
                  }
                }

                const custom = formState.patchCustom;

                let customLimit = 0;
                for (let i = 0; i < custom.length; i++) {
                  if (custom[i].length >= 1) {
                    for (let j = 0; j < custom[i].length; j++) {
                      customLimit += 1;
                    }
                  }
                }
                const newSubject = formState.patchSubject;
                const reduxPrice = newSubject.reduce((total: any, item: any) => {
                  return total + item.current;
                }, 0);

                formState.total = reduxPrice + customLimit;
              }}
            >
              {formState.subjectList.map((item: any, index: number) => {
                return (
                  <Algorithm
                    onChange={setChange(formState)}
                    totalChange={setTotleChange(formState)}
                    subjectChange={setSubjectCustom(formState)}
                    dataDefault={item}
                    customData={subjectCustomStore && subjectCustomStore[index]}
                  />
                );
              })}
            </Checkbox.Group>
            <Row justify="end">
              <Col>
                <Text class="title">
                  共{formState.total}道题,平均每题
                  {round(100 / formState.total, 2)}分
                </Text>
                <Button class="submit-btn" type="primary" onClick={sumbit(formState, $router)}>
                  确定
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  },
});
export default Home;
