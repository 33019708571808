import {
  Form,
  Input,
  Checkbox,
  Row,
  Button,
  InputNumber,
  Tag,
  message,
} from "ant-design-vue";
import { defineComponent, reactive } from "vue";
import { filterUnit, localOperation } from "@/utils/index";
import { CloseOutlined } from '@ant-design/icons-vue';

import "./index.less";
const names = ["加法", "减法", "乘法", "整数除法", "余数除法"];
const reduce = (formState: any, type: string) => {
  if (formState[type] <= 1) {
    formState[type] = 1;
    return;
  }
  formState[type] -= 1;
};
const add = (formState: any, type: string) => {
  if (formState[type] >= 5) {
    formState[type] = 5;
    return;
  }
  formState[type] += 1;
};
const close = (state: any, id: number, props: any, formState: any) => () => {
  state.formDataList = state.formDataList.filter((item: any) => {
    return item.id !== id;
  });
  props.totalChange(-1)
  props.subjectChange(state.formDataList, formState.type)
};
/* 验证判断 */
const verification = (props: any, state: any, formState: any, item: any) => {
  /* 减法 */
  if (item.type === 2) {
    if (item.L - item.R < 0) {
      item.verification = false;
    } else {
      item.verification = true;
    }
    console.log(item.L - item.R);
  }
  /* 整数除法 */
  if (item.type === 4) {
    if (item.L % item.R != 0) {
      item.verification = false;
    } else {
      item.verification = true;
    }
    console.log(item.L / item.R);
  }
  /* 整数除法 */
  if (item.type === 5) {
    if (item.L % item.R === 0) {
      item.verification = false;
    } else {
      item.verification = true;
    }
    console.log(item.L / item.R);
  }
  if (item.verification) {
    props.subjectChange(state.formDataList, formState.type)
  } else {
    message.error('你填写有误，请修改带有X错误提示的部分，否则不能进行保存')
  }

}
const Addition = defineComponent({
  props: ["dataDefault", "onChange", "totalChange", "subjectChange", "customData"],
  setup(props) {
    console.log(props.customData)
    const formState = reactive({
      ...props.dataDefault,
    });
    const state: any = reactive({
      formDataList: props.customData || [],
      id: props.customData && props.customData[props.customData.length - 1]?.id + 1 || 0,
      iconStyle: { color: 'red', position: 'relative', top: '10px', left: '10px' }
    });
    const slots = (type: string) => ({
      addonBefore: () => (
        <div
          class="clear"
          onClick={() => {
            if (type === "l") {
              reduce(formState, "digitL");
              props.onChange(formState);
            } else {
              reduce(formState, "digitR");
              props.onChange(formState);
            }
          }}
        >
          －
        </div>
      ),
      addonAfter: () => (
        <div
          class="add"
          onClick={() => {
            if (type === "l") {
              add(formState, "digitL");
              props.onChange(formState);
            } else {
              add(formState, "digitR");
              props.onChange(formState);
            }
          }}
        >
          ＋
        </div>
      ),
    });
    return () => (
      <Row class="set-item">
        <Row align="middle" justify="space-between">
          <Checkbox value={formState.type}>
            {names[formState.type - 1]}
          </Checkbox>
          <Form.Item label="位数" name="title">
            <Input
              type="number"
              disabled
              v-model={[formState.digitL, "value"]}
              v-slots={slots("l")}
            />
            <Input
              type="number"
              disabled
              v-model={[formState.digitR, "value"]}
              v-slots={slots("r")}
            />
          </Form.Item>
          <Form.Item label="随机" name="title">
            <InputNumber
              
              min={1}
              style={{ marginRight: "20px" }}
              v-model={[formState.time, "value"]}
              onChange={(e) => {
                props.onChange(formState);
              }}
            />
            <InputNumber
              min={1}
              v-model={[formState.current, "value"]}
              onChange={(e) => {
                props.onChange(formState);
              }}
            />
          </Form.Item>
        </Row>
        {state.formDataList.length >= 1 &&
          state.formDataList.map((item: any) => {
            return (
              <Row
                key={item.id}
                align="middle"
                justify="space-between"
                class="set-item-cont"
              >
                <Tag closable color="#108ee9" onClose={close(state, item.id, props, formState)}>
                  {names[formState.type - 1]}
                </Tag>
                <Form.Item label="" name="title" class="item-cont-desc">
                  <InputNumber
                    min={1}
                    v-model={[item.L, "value"]}
                    onChange={(e) => {
                      if (item.R) {
                        verification(props, state, formState, item)
                      }
                    }}
                  />
                  <span class="ant-input-group-addon">
                    <div class="add">{filterUnit(+formState.type)}</div>
                  </span>
                  <InputNumber
                    min={1}
                    v-model={[item.R, "value"]}
                    onChange={(e) => {
                      if (item.L) {
                        verification(props, state, formState, item)
                      }
                    }}
                  />
                  {!item.verification && <CloseOutlined style={state.iconStyle} />}
                </Form.Item>
                <Form.Item label="固定" name="title">
                  <InputNumber
                    min={1}
                    style={{ marginRight: "20px" }}
                    v-model={[item.time, "value"]}
                    onChange={(e) => {
                      if (item.L) {
                        verification(props, formState, state, item)
                      }
                    }}
                  />
                  <InputNumber
                    min={1}
                    value={1}
                    disabled
                  />

                </Form.Item>
              </Row>
            );
          })}
        <Button
          class="add-btn"
          onClick={() => {
            const pushJson = {
              id: state.id++,
              L: 1,
              R: formState.type === 5 ? 2 : 1,
              time: 100,
              result: "",
              type: formState.type,
              value: "",
              verification: true,
            };
            props.totalChange(1)
            state.formDataList.push(pushJson);
            props.subjectChange(state.formDataList, formState.type)
          }}
        >
          ............
        </Button>
      </Row>
    );
  },
});
export default Addition;
